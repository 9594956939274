import { translateSingular, translatePlural } from '/i18n/translations'
import { ClientConfigProvider } from '/machinery/ClientConfig'
import { I18nProvider } from '/machinery/I18n'
import { reportClientError } from '/machinery/reportClientError'
import { ReportErrorProvider } from '/machinery/ReportError'

// eslint-disable-next-line @kaliber/no-default-export
export default function ClientWrapper({ children, ...props }) {
  const { clientContext } = props

  return (
    <ReportErrorProvider reportError={clientContext.reportError || reportClientError}>
      <ClientConfigProvider config={clientContext.clientConfig}>
        <I18nProvider language={clientContext.language} {...{ translateSingular, translatePlural }}>
          {children}
        </I18nProvider>
      </ClientConfigProvider>
    </ReportErrorProvider>
  )
}
